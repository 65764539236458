import React, { useContext } from "react";

// import components
import CountryDropdown from "../CountryDropdown/CountryDropdown";
import PropertyDropdown from "../PropertyDropdown/PropertyDropdown";
import PriceRangeDropdown from "../PriceRangeDropdown/PriceRangeDropdown";

// import context
import { HouseContext } from "../../context/HouseContext";

// import icon
import { RiSearch2Line } from "react-icons/ri";
import StateDropdown from "../StateDropdown/StateDropdown";

const Search = () => {
  const { handleClick } = useContext(HouseContext);
  return (
    <div className="px-[30px] py-6 max-w-[1300px] mx-auto flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 relative lg:-top-4 lg:shadow-1 bg-white lg:backdrop-blur rounded-lg z-40 -mt-8">
      <CountryDropdown />
      <PropertyDropdown />
      <StateDropdown />
      <button
        onClick={() => {
          handleClick();
        }}
        className="bg-primary hover:opacity-80 transition w-full lg:max-w-[162px] h-16 rounded-lg flex justify-center items-center text-white text-lg"
      >
        <RiSearch2Line />
      </button>
    </div>
  );
};

export default Search;
