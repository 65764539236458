import React, { createContext, useState, useEffect } from "react";
import { housesData } from "../data";

export const HouseContext = createContext();

const HouseContextProvider = ({ children }) => {
  const [houses, setHouses] = useState(housesData); // mantiene la lista filtrada actual
  const [country, setCountry] = useState("Todas las localidades");
  const [countries, setCountries] = useState([]);
  const [property, setProperty] = useState("Todas las Propiedades");
  const [properties, setProperties] = useState([]);
  const [price, setPrice] = useState("Rango de Precio");
  const [state, setState] = useState("Todos los estados");
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Obtener los países únicos
    const allCountries = housesData.map((house) => house.country);
    const uniqueCountries = ["Todas las localidades", ...new Set(allCountries)];
    setCountries(uniqueCountries);

    // Obtener los tipos de propiedades únicos
    const allProperties = housesData.map((house) => house.type);
    const uniqueProperties = [
      "Todas las Propiedades",
      ...new Set(allProperties),
    ];
    setProperties(uniqueProperties);

    // Obtener los estados únicos (Venta, Alquiler, etc.)
    const allStates = housesData.map((house) => house.state);
    const uniqueStates = ["Todos los estados", ...new Set(allStates)];
    setStates(uniqueStates);
  }, []);

  const handleClick = () => {
    setLoading(true);

    const minPrice = parseInt(price.split(" ")[0]);
    const maxPrice = parseInt(price.split(" ")[2]);

    // Filtrar utilizando housesData en lugar de houses
    const newHouses = housesData.filter((house) => {
      const housePrice = parseInt(house.price);

      return (
        (country === "Todas las localidades" || house.country === country) &&
        (property === "Todas las Propiedades" || house.type === property) &&
        (state === "Todos los estados" || house.state === state) &&
        (price === "Rango de Precio" ||
          (housePrice >= minPrice && housePrice <= maxPrice))
      );
    });

    setTimeout(() => {
      setHouses(newHouses.length < 1 ? [] : newHouses);
      setLoading(false);
    }, 1000);
  };

  return (
    <HouseContext.Provider
      value={{
        country,
        setCountry,
        countries,
        property,
        setProperty,
        properties,
        price,
        setPrice,
        state,
        setState,
        states,
        handleClick,
        houses,
        loading,
      }}
    >
      {children}
    </HouseContext.Provider>
  );
};

export default HouseContextProvider;
