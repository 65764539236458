import React from "react";
// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";
// import swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
// import required modules
import { EffectFade, Autoplay } from "swiper";
// images
import Hero1 from "../../assets/img/hero/hero1.jpg";
import Hero3 from "../../assets/img/hero/hero3.jpg";

import Search from "../Search/Search";

const slides = [
  {
    title: "Encuentra el hogar de tus sueños con nosotros",
    bg: Hero1,
  },
  {
    title: "La llave de tu nueva vida está aquí",
    bg: Hero3,
  },
];

const HeroSlider = () => {
  return (
    <div>
      <Swiper
        modules={[EffectFade, Autoplay]}
        effect={"fade"}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="heroSlider h-[600px] lg:h-[860px]"
      >
        {slides.map((slide, index) => {
          // destructure slide
          const { title, bg } = slide;
          return (
            <SwiperSlide
              className="h-full relative flex justify-center items-center"
              key={index}
            >
              <div className="z-20 text-white text-center">
                <h1 className="text-4xl lg:text-[58px] font-semibold leading-none mb-6">
                  {title}
                </h1>
              </div>
              <div className="absolute top-0 w-full h-full">
                <img className="object-cover h-full w-full" src={bg} alt="" />
              </div>
              {/* overlay */}
              <div className="absolute w-full h-full bg-black/40"></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Search />
    </div>
  );
};

export default HeroSlider;
