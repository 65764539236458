import Logo from "../../assets/img/logos/logoWhite.png";

const Footer = () => {
  return (
    <footer className="px-3 p-4 text-white transition-colors duration-200 bg-primary sm:py-8">
      <div className="flex flex-col">
        <div className="h-px mx-auto rounded-full md:hidden w-11"></div>
        <div className="flex flex-col mt-4 md:mt-0 md:flex-row">
          <nav className="flex flex-col items-center justify-center flex-1 border-gray-100 md:items-end md:border-r md:pr-5">
            <a href="/" className="hover:text-gray-700">
              Home
            </a>
            <a href="/tipo/Venta" className="hover:text-gray-700">
              Ventas
            </a>
            <a href="/tipo/Alquiler" className="hover:text-gray-700">
              Alquileres
            </a>
          </nav>
          <div className="h-px mx-auto mt-4 rounded-full md:hidden w-11"></div>
          <div className="flex items-center justify-center flex-1 mt-4 border-gray-100 md:mt-0 md:border-r">
            <img src={Logo} alt="logo" className="w-48" />
          </div>
          <div className="h-px mx-auto mt-4 rounded-full md:hidden w-11 "></div>
          <div className="flex flex-col items-center justify-center flex-1 mt-2 md:mt-0 md:items-start md:pl-5">
            <span className="">Matricula 1568</span>
            <span className="mt-2">Tomo III Folio 912</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
