import {
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaWhatsapp,
  FaEnvelope,
  FaFacebookSquare,
} from "react-icons/fa";
import Logo from "../../assets/img/logos/logoColor.png";

function Location() {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex justify-center rounded-md">
        <iframe
          width="90%"
          height="95%"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="map"
          scrolling="no"
          className="rounded-lg"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.2954911820043!2d-58.014630399999994!3d-35.571097800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95980d51bc70cf71%3A0xd3ab056522d0f45c!2sLibres%20del%20Sur%20348%20Local%203%2C%20B7130%20Chascom%C3%BAs%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1695242629018!5m2!1sen!2sar"
        ></iframe>
      </div>
      <div className="container px-5 py-24 mx-auto flex">
        <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative shadow-md">
          <div className="text-primary w-full">
            <img src={Logo} alt="logo" className="w-48" />
            <p className="mt-6 max-w-xl">Negocios Inmobiliarios</p>

            <div className="mt-6 space-y-8 md:mt-8">
              <p className="flex items-start">
                <FaMapMarkerAlt className="text-primary w-6 h-6" />
                <span className="mx-4 truncate text-black">
                  Arenales 302 (Oficina 1), Chascomús
                </span>
              </p>

              <p className="flex items-start">
                <FaPhoneAlt className="text-primary w-6 h-6" />
                <span className="mx-4 w-72 truncate text-black">
                  (2241) 49-2969
                </span>
              </p>

              <p className="flex items-start">
                <FaEnvelope className="text-primary w-6 h-6" />
                <span className="mx-4 w-72 truncate text-black">
                  claudiasauloinmobiliaria@gmail.com
                </span>
              </p>
            </div>

            <div className="mt-6 md:mt-8">
              <h3 className="text-primary">Contactanos</h3>

              <div className="mt-4 flex">
                <div className="ml-2 mr-2">
                  <a href={"https://www.instagram.com/claudiasaulo"}>
                    <span className="cursor-pointer">
                      <FaInstagram className="h-7 w-7 text-black hover:text-primary" />
                    </span>
                  </a>
                </div>

                {/* 
<div className="ml-2 mr-2">
                  <a
                    href={
                      "https://www.facebook.com/profile.php?id=100092388815144"
                    }
                  >
                    <span className="cursor-pointer">
                      <FaFacebookSquare className="h-7 w-7 text-black hover:text-primary" />
                    </span>
                  </a>
                </div>
*/}

                <div className="ml-2 mr-2">
                  <a
                    href={`https://api.whatsapp.com/send?phone=5492241492969&text=Hola%Claudia,%20quisiera%20realizar%20una%20consulta,%20Gracias!`}
                  >
                    <span className="cursor-pointer">
                      <FaWhatsapp className="h-7 w-7 text-black hover:text-primary" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
