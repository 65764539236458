import React, { useState, useContext } from "react";
import { HouseContext } from "../../context/HouseContext";
import House from "../House/House";
import { Link } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";

const HouseList = () => {
  const { houses, loading } = useContext(HouseContext);
  const [visibleHouses, setVisibleHouses] = useState(6); // Mostrar 6 casas inicialmente

  const showMoreHouses = () => {
    setVisibleHouses((prevVisibleHouses) => prevVisibleHouses + 6);
  };

  if (loading) {
    return (
      <ImSpinner2 className="mx-auto animate-spin text-primary text-4xl my-[200px]" />
    );
  }

  if (houses.length < 1) {
    return (
      <div className="text-center text-3xl text-gray-400 my-[200px]">
        Lo sentimos, no hemos encontrado resultados
      </div>
    );
  }

  return (
    <section className="mb-20">
      <div className="container mx-auto max-w-[1360px]">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14 justify-center">
          {houses.slice(0, visibleHouses).map((house, index) => (
            <Link to={`/propiedad/${house.id}`} key={index}>
              <House house={house} />
            </Link>
          ))}
        </div>
        {visibleHouses < houses.length && (
          <div className="text-center mt-8">
            <button
              onClick={showMoreHouses}
              className="bg-primary text-white px-4 py-2 rounded hover:opacity-80"
            >
              Ver más Propiedades
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default HouseList;
