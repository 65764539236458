import React from "react";
import { Helmet } from "react-helmet";
import Logo from "../../assets/img/logos/logoColor.png";

// import components
import Banner from "../../components/Banner/Banner";
import HouseList from "../../components/HouseList";
import HeroSlider from "../../components/HeroSlider";
import Location from "../../components/Location";

const Home = () => {
  return (
    <>
      {/* SEO Metadatos */}
      <Helmet>
        <title>Clausia Saulo Inmobiliaria</title>
        <meta
          name="description"
          content="Descubre las mejores propiedades en venta y alquiler. Casas, departamentos y más en Inmobiliaria Claudia Saulo."
        />
        <meta
          name="keywords"
          content="inmobiliaria, casas en venta, departamentos, alquiler, bienes raíces, propiedades"
        />
        <meta
          property="og:title"
          content="Inmobiliaria Claudia Saulo - Encuentra tu hogar ideal"
        />
        <meta
          property="og:description"
          content="Descubre las mejores propiedades en venta y alquiler. Casas, departamentos y más en Inmobiliaria Claudia Saulo."
        />
        <meta
          property="og:image"
          content={Logo} // Actualiza con la URL de tu imagen
        />
        <meta property="og:url" content="https://www.claudiasaulo.com" />
      </Helmet>

      <HeroSlider />
      <div className="sm:pt-6">
        <HouseList />
      </div>
      <div className="max-w-[1440px] mx-auto">
        <Location />
      </div>
    </>
  );
};

export default Home;
