// import icons
import { BiBed, BiBath, BiArea } from "react-icons/bi";

const House = ({ house }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg hover:shadow-xl">
      <div className="w-full h-64 p-2 relative">
        <div className="bg-red-100 w-full flex justify-end">
          <div className="absolute m-2 bg-primary rounded-full text-white px-3">
            {house.state}
          </div>
        </div>

        <img
          className="w-full h-full object-cover rounded-md"
          src={house.imageCard}
          alt={house.name}
        />
      </div>
      <div className="px-4 py-4">
        <div className="mb-4 flex gap-x-2 text-sm">
          <div className="bg-primary rounded-full text-white px-3 inline-block">
            {house.type}
          </div>
          <div className="bg-primary rounded-full text-white px-3 inline-block">
            {house.country}
          </div>
        </div>
        <div className="mb-2">
          <h2 className="text-xl font-bold text-gray-900"> {house.address}</h2>
        </div>
        <div className="flex gap-x-4 my-4">
          <div className="flex items-center text-gray-600 gap-1">
            <div className="text-[20px] rounded-full">
              <BiBed />
            </div>
            <div className="text-base">{house.bedrooms}</div>
          </div>
          <div className="flex items-center text-gray-600 gap-1">
            <div className="text-[20px] rounded-full">
              <BiBath />
            </div>
            <div className="text-base">{house.bathrooms}</div>
          </div>
          <div className="flex items-center text-gray-600 gap-1">
            <div className="text-[20px] rounded-full">
              <BiArea />
            </div>
            <div className="text-base">{house.surface}</div>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-2xl font-extrabold text-primary">
            {house.currency !== "" ? (
              <>
                {house.currency} {house.price}
              </>
            ) : (
              "Consultar"
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default House;
