import React, { useState } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import { housesData } from "../../data";
import { useParams } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { FreeMode, Navigation, Thumbs, Autoplay, EffectFade } from "swiper";
import FSLightbox from "fslightbox-react"; // Importa FSLightbox
import { Helmet } from "react-helmet";

const PropertyDetails = () => {
  const { id } = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxToggler, setLightboxToggler] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // Busca la propiedad usando el ID
  const property = housesData.find((house) => house.id === parseInt(id));

  // Función para abrir el lightbox al hacer clic en una imagen
  const openLightbox = (index) => {
    setPhotoIndex(index + 1);
    setLightboxToggler(!lightboxToggler); // Alterna el estado del lightbox
  };

  // Función para manejar los cambios en los inputs del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Función para verificar si todos los campos del formulario están completos
  const isFormComplete = () => {
    return (
      formData.name && formData.email && formData.phone && formData.message
    );
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    const whatsappMessage = encodeURIComponent(`
      *Consulta sobre la propiedad* \n
      *Nombre*: ${formData.name} \n
      *Email*: ${formData.email} \n
      *Teléfono*: ${formData.phone} \n
      *Mensaje*: ${formData.message} \n\n
      *Detalles de la propiedad* \n
      *Nombre*: ${property.name} \n
      *Dirección*: ${property.address} \n
      *Localidad*: ${property.country} \n
      *Tipo*: ${property.type} \n
      *Precio*: ${property.currency} ${
      property.price !== "" ? property.price : "Consultar"
    } \n
      *Descripción*: ${property.description}
    `);

    const whatsappLink = `https://wa.me/+5492241492969?text=${whatsappMessage}`;

    // Abre el enlace de WhatsApp en una nueva pestaña
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container mx-auto min-h-[800px] mt-32 mb-14">
      <Helmet>
        <title>{property.name}</title>
        <meta
          name="description"
          content={`Consulta sobre ${property.name}. ${property.description}`}
        />
        <meta
          name="keywords"
          content={`${property.name}, ${property.type}, ${property.country}, propiedades, alquiler`}
        />
      </Helmet>
      <ScrollToTop />
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div>
          <h2 className="text-2xl font-semibold">
            {property.name} {property.state}
          </h2>
          <h3 className="text-lg mb-4">{property.address}</h3>
        </div>
        <div className="mb-4 lg:mb-0 flex gap-x-2 text-sm">
          <div className="bg-primary rounded-full text-white px-3 inline-block">
            {property.type}
          </div>
          <div className="bg-primary rounded-full text-white px-3 inline-block">
            {property.country}
          </div>
        </div>
        <div className="text-3xl font-semibold text-black">
          {property.currency !== "" ? (
            <>
              {property.currency} {property.price}
            </>
          ) : (
            "Consultar"
          )}
        </div>
      </div>
      <div className="flex flex-col items-start gap-8 lg:flex-row">
        <div className="w-full h-full lg:w-[65%] sm:px-6">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
            modules={[FreeMode, Navigation, Thumbs, Autoplay, EffectFade]}
            className="mySwiper2"
            effect="fade"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            centeredSlides={true}
            slidesPerView={1}
            grabCursor={true}
          >
            {property.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Room ${index}`}
                  className="w-full object-cover h-[400px] sm:h-[600px] cursor-zoom-in"
                  onClick={() => openLightbox(index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper margin-0 padding-0 border-none h-[88px]"
            breakpoints={{
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
          >
            {property.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Room ${index}`}
                  className="w-full h-auto object-cover border-none mt-2 transform transition-transform duration-300 hover:scale-110 cursor-pointer"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex-1 w-full mb-8 bg-white border border-gray-300 rounded-lg px-6 py-8">
          <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
            <input
              className="border border-gray-300 focus:border-primary rounded w-full px-4 h-14 text-sm outline-none"
              type="text"
              name="name"
              placeholder="Nombre*"
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              className="border border-gray-300 focus:border-primary rounded w-full px-4 h-14 text-sm outline-none"
              type="email"
              name="email"
              placeholder="Email*"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              className="border border-gray-300 focus:border-primary rounded w-full px-4 h-14 text-sm outline-none"
              type="text"
              name="phone"
              placeholder="Teléfono*"
              value={formData.phone}
              onChange={handleInputChange}
            />
            <textarea
              className="border border-gray-300 focus:border-primary rounded w-full p-4 h-36 text-sm text-gray-400 outline-none resize-none"
              name="message"
              placeholder="Mensaje*"
              value={formData.message}
              onChange={handleInputChange}
            />
            <div className="flex gap-x-2">
              <button
                className={`${
                  isFormComplete()
                    ? "bg-primary hover:opacity-75"
                    : "bg-gray-400 cursor-not-allowed"
                } text-white rounded p-4 text-sm w-full transition flex justify-center items-center`}
                type="submit"
                disabled={!isFormComplete()} // Deshabilitar el botón si el formulario no está completo
              >
                <FaWhatsapp className="text-2xl mr-2" />
                Enviar mensaje
              </button>
            </div>
          </form>
        </div>
      </div>
      <FSLightbox
        toggler={lightboxToggler}
        sources={property.images}
        slide={photoIndex}
      />
      <div className="flex items-center justify-center py-8">
        <div className="bg-white w-full">
          <div className="p-4 border-b">
            <h2 className="text-2xl ">Descripción</h2>
          </div>
          <div>
            <div className="hover:bg-gray-50 md:space-y-0 space-y-3 px-4 py-4 text-gray-700 text-sm">
              <p>{property.description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2">
        {Object.entries(property.characteristics).map(([key, value], i) => (
          <div key={i} className="hover:bg-gray-50 p-4 border-b">
            <p className="text-gray-600">{key}</p>
            <p>{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PropertyDetails;
