import React, { useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { HouseContext } from "../../context/HouseContext";
import House from "../../components/House/House";
import { Helmet } from "react-helmet";

const PropertyType = () => {
  const { type } = useParams(); // Recibe el parámetro de tipo desde la URL
  const { houses, loading } = useContext(HouseContext);
  const [visibleHouses, setVisibleHouses] = useState(6); // Mostrar 6 casas inicialmente

  // Filtra las casas por el tipo (si existe el parámetro 'tipe')
  const filteredHouses = type
    ? houses.filter((house) => house.state === type) // Ajusta el campo `type` según cómo esté estructurado tu objeto
    : houses;

  const showMoreHouses = () => {
    setVisibleHouses((prevVisibleHouses) => prevVisibleHouses + 6);
  };

  if (loading) {
    return (
      <ImSpinner2 className="mx-auto animate-spin text-primary text-4xl my-[200px]" />
    );
  }

  if (filteredHouses.length < 1) {
    return (
      <div className="text-center text-3xl text-gray-400 my-[200px]">
        Lo sentimos, no hemos encontrado resultados
      </div>
    );
  }

  return (
    <section className="my-32">
      <Helmet>
        <title>
          {type ? `Propiedades en ${type}` : "Todas las Propiedades"}
        </title>
        <meta
          name="description"
          content={`Explora las propiedades en ${
            type || "nuestra plataforma"
          } para encontrar la casa ideal. Verifica opciones de ${
            type || "tipos de propiedad"
          } disponibles.`}
        />
        <meta
          name="keywords"
          content={`propiedades, ${
            type ? type : "casas"
          }, alquiler, venta, compra, inmuebles`}
        />
      </Helmet>
      <div className="container mx-auto max-w-[1360px]">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14 justify-center">
          {filteredHouses.slice(0, visibleHouses).map((house, index) => (
            <Link to={`/propiedad/${house.id}`} key={index}>
              <House house={house} />
            </Link>
          ))}
        </div>
        {visibleHouses < filteredHouses.length && (
          <div className="text-center mt-8">
            <button
              onClick={showMoreHouses}
              className="bg-primary text-white px-4 py-2 rounded hover:opacity-80"
            >
              Ver más Propiedades
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default PropertyType;
