import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logos/logoColor.png";
import LogoWhite from "../../assets/img/logos/logoWhite.png";

const Header = () => {
  const [header, setHeader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isHomePage = window.location.pathname === "/";

  useEffect(() => {
    if (isHomePage) {
      const handleScroll = () => {
        setHeader(window.scrollY > 50);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setHeader(true); // For other pages, keep the header background and text black by default
    }
  }, [isHomePage]);

  return (
    <header
      id="header"
      className={`${
        header ? "bg-white py-6 shadow-lg" : "bg-transparent py-8"
      } fixed z-50 w-full transition-all duration-500 ${
        header || !isHomePage ? "text-black" : "text-white"
      }`}
    >
      <div className="lg:container lg:mx-auto flex flex-col items-center justify-between lg:flex-row">
        <div className="flex justify-between w-full px-4 sm:px-0">
          <a href="/">
            {/* Alternar entre el logo blanco y el de color */}
            <img
              className="w-40 transition-all duration-300"
              src={header || !isHomePage ? Logo : LogoWhite}
              alt="Logo"
            />
          </a>
          <div className="lg:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`focus:outline-none ml-4 cursor-pointer ${
                header || !isHomePage ? "text-black" : "text-white"
              }`}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
        </div>

        <nav
          className={`${
            isOpen
              ? "flex flex-col items-center w-full gap-8 lg:bg-transparent animate-slideDown"
              : "hidden"
          } lg:flex lg:flex-row lg:items-center lg:gap-x-8 lg:w-auto lg:py-0 lg:bg-transparent font-medium py-8 tracking-[3px] text-[15px] uppercase ${
            header || !isHomePage ? "text-black" : "text-white"
          }`}
        >
          <a
            href="/"
            className="hover:opacity-80 transition duration-300 ease-in-out"
          >
            Home
          </a>
          <a
            href="/tipo/Alquiler"
            className="hover:opacity-80 transition duration-300 ease-in-out"
          >
            Alquileres
          </a>
          <a
            href="/tipo/Alquiler Temporal"
            className="hover:opacity-80 transition duration-300 ease-in-out"
          >
            Temporales
          </a>
          <a
            href="/tipo/Venta"
            className="hover:opacity-80 transition duration-300 ease-in-out"
          >
            Ventas
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
