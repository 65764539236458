import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { FaWhatsapp } from "react-icons/fa";

// import pages
import Home from "./pages/Home";
import PropertyDetails from "./pages/PropertyDetails";
import PropertyType from "./pages/PropertyType";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/propiedad/:id",
    element: <PropertyDetails />,
  },
  {
    path: "/tipo/:type",
    element: <PropertyType />,
  },
]);

const App = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow">
        <RouterProvider router={router} />
      </main>
      <Footer />
      {/* Botón de WhatsApp */}
      <a
        href={`https://api.whatsapp.com/send?phone=5492241492969&text=Hola%20Claudia,%20quisiera%20realizar%20una%20consulta,%20Gracias!`}
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-5 right-5 bg-accent text-white p-3 rounded-full shadow-lg bg-primary hover:opacity-80 transition-colors z-50"
      >
        <FaWhatsapp className="h-10 w-10" />
      </a>
    </div>
  );
};

export default App;
