// Utilidad para cargar imágenes dinámicamente
const importAll = (requireContext) => requireContext.keys().map(requireContext);

// Cargar imágenes de casas
const housesImages1 = importAll(
  require.context("./assets/img/houses/1", false, /\.(jpe?g|png)$/)
);
const housesImages2 = importAll(
  require.context("./assets/img/houses/2", false, /\.(jpe?g|png)$/)
);
const housesImages3 = importAll(
  require.context("./assets/img/houses/3", false, /\.(jpe?g|png)$/)
);
const housesImages4 = importAll(
  require.context("./assets/img/houses/4", false, /\.(jpe?g|png)$/)
);
const housesImages5 = importAll(
  require.context("./assets/img/houses/5", false, /\.(jpe?g|png)$/)
);

// Cargar imágenes de departamentos
const apartmentsImages1 = importAll(
  require.context("./assets/img/apartments/1", false, /\.(jpe?g|png)$/)
);
const apartmentsImages2 = importAll(
  require.context("./assets/img/apartments/2", false, /\.(jpe?g|png)$/)
);
const apartmentsImages3 = importAll(
  require.context("./assets/img/apartments/3", false, /\.(jpe?g|png)$/)
);

// Cargar imágenes de locales
const localImages1 = importAll(
  require.context("./assets/img/local/1", false, /\.(jpe?g|png)$/)
);

// Cargar imágenes de terrenos
const terrainsImages1 = importAll(
  require.context("./assets/img/terrains/1", false, /\.(jpe?g|png)$/)
);

// Cargar imágenes de duplex
const duplexImages1 = importAll(
  require.context("./assets/img/duplex/1", false, /\.(jpe?g|png)$/)
);

export const housesData = [
  {
    id: 1,
    type: "Casa",
    state: "Venta",
    name: "Casa en barrio Colón",
    description:
      "EN VENTA: Hermosa casa en el Barrio Colón, ubicada a solo 300 metros de la Laguna en Chascomús. Con 145 m² de superficie total y 115 m² cubiertos, cuenta con un amplio y luminoso living, cocina-comedor, tres dormitorios y dos baños. Además, ofrece un patio interno y otro exterior, ideal para disfrutar al aire libre. Incluye garaje y todos los servicios disponibles (agua, luz, gas y cloacas). Esta propiedad combina comodidad y una excelente ubicación cercana a la naturaleza.",
    characteristics: {
      Ambientes: "+4",
      Dormitorios: "3",
      Baños: "2",
      Cochera: "Si",
      Patio: "Si",
      Expensas: "No",
      Ubicación: "Frente",
      Servicios: "Agua corriente, gas natural, electricidad, cloacas",
      "Estado del inmueble": "Bueno",
      "Cantidad de plantas": "1",
      "Superficie cubierta": "115 m²",
      "Superficie total": "145 m²",
      "Distancia a la laguna": "300 metros",
    },
    imageCard: housesImages1[0], // Primer imagen de casas
    images: housesImages1, // Todas las imágenes de la primera casa
    country: "Chascomús",
    address: "La Rioja (entre Moreno y Castelli)",
    bedrooms: "3",
    bathrooms: "2",
    surface: "145 m2",
    currency: "USD",
    price: "85000",
  },
  {
    id: 2,
    type: "Departamento",
    state: "Venta",
    name: "Departamento Calle 14 N' 730",
    description:
      "EN VENTA: Lindo y soleado departamento en el contrafrente, ubicado en un edificio de 9 pisos bien cuidado con un ascensor moderno. Este espacio cuenta con una cocina equipada con desayunador, un living-comedor amplio, dos habitaciones, un baño completo y un balcón con excelente luz natural. Su orientación permite recibir abundante sol, creando un ambiente cálido y agradable. Una excelente opción para quienes buscan comodidad y luz natural en una ubicación céntrica entre las calles 46 y 47.",
    characteristics: {
      Ambientes: "3",
      Dormitorios: "2",
      Baños: "1",
      Cochera: "No",
      Patio: "No",
      Expensas: "Si",
      Ubicación: "Contrafrente",
      Servicios: "Agua corriente, gas natural, electricidad, cloacas",
      "Estado del inmueble": "Muy bueno",
      "Cantidad de plantas": "1",
      "Superficie cubierta": "46 m2",
      "Superficie total": "50 m2",
      "Otros espacios": "Balcón y cocina con desayunador",
    },
    imageCard: apartmentsImages1[0], // Primer imagen de apartamentos
    images: apartmentsImages1, // Todas las imágenes de apartamentos
    country: "La Plata",
    address: "Calle 14 N' 730",
    bedrooms: "2",
    bathrooms: "1",
    surface: "50 m2",
    currency: "USD",
    price: "75000",
  },
  {
    id: 3,
    type: "Departamento",
    state: "Venta",
    name: "Departamento Calle 1 entre 62 y 63",
    description:
      "EN VENTA: Este departamento monoambiente se encuentra en el primer piso de un edificio de 9 pisos, con ascensor. Está orientado hacia el contrafrente, lo que le permite recibir buena luz natural. El departamento cuenta con cocina equipada, placard y un balcón. Actualmente está alquilado en $250.000 hasta febrero de 2025, lo que lo convierte en una excelente opción de inversión.",
    characteristics: {
      Ambientes: "1",
      Dormitorios: "0",
      Baños: "1",
      Cochera: "No",
      Patio: "No",
      Expensas: "Consultar",
      Ubicación: "Contrafrente",
      Servicios:
        "Agua corriente, gas natural, electricidad, cloacas, internet, cable",
      "Estado del inmueble": "Muy bueno",
      "Cantidad de plantas": "1",
      "Superficie cubierta": "33 m²",
      "Superficie total": "33 m²",
      "Otros espacios": "Balcón",
    },
    imageCard: apartmentsImages2[0], // Primer imagen de apartamentos 2
    images: apartmentsImages2, // Todas las imágenes de apartamentos 2
    country: "La Plata",
    address: "Calle 1 entre 62 y 63",
    bedrooms: "0",
    bathrooms: "1",
    surface: "50 m2",
    currency: "USD",
    price: "40000",
  },
  {
    id: 4,
    type: "Casa",
    state: "Venta",
    name: "Casa en Chascomús",
    description:
      "EN VENTA: Se ofrece en venta una casa con 3 dormitorios, cocina completa, baño y lavadero, ubicada en Chascomús, sobre un terreno de 2812.50 m². La propiedad incluye un amplio parque, galería y parrilla. Además, cuenta con 4 departamentos, cada uno con cocina-comedor, baño, habitación, galería y parrilla. Ubicación: Calle de los Biguás N°18, Chascomús, Provincia de Buenos Aires. Ideal para inversores o quienes buscan una propiedad con múltiples unidades de alquiler.",
    characteristics: {
      Ambientes: "4",
      Dormitorios: "3",
      Baños: "1",
      Cochera: "No",
      Patio: "Si",
      Expensas: "No",
      Ubicación: "Frente",
      Servicios: "Agua corriente, gas natural, electricidad",
      "Estado del inmueble": "Bueno",
      "Cantidad de plantas": "1",
      "Superficie cubierta": "322.71 m²",
      "Superficie total": "2815.50 m²",
    },
    imageCard: housesImages2[0], // Primer imagen de casas 2
    images: housesImages2, // Todas las imágenes de casas 2
    country: "Chascomús",
    address: "Calle Dw Los Biguas al 100",
    bedrooms: "4",
    bathrooms: "1",
    surface: "2815.50 m2",
    currency: "USD",
    price: "430000",
  },
  {
    id: 5,
    type: "Local",
    state: "Alquiler",
    name: "Local Comercial",
    description:
      "EN Alquiler: Se ofrece en alquiler un local comercial que posee planta baja con local, baño y patio. Y una planta alta con oficina/deposito",
    characteristics: {
      Ambientes: "3",
      Dormitorios: "1",
      Baños: "1",
      Cochera: "No",
      Patio: "Si",
      Expensas: "No",
      Ubicación: "Frente",
      Servicios: "Agua corriente, gas natural, electricidad",
      "Estado del inmueble": "Bueno",
      "Cantidad de plantas": "2",
    },
    imageCard: localImages1[0], // Primer imagen de casas 2
    images: localImages1, // Todas las imágenes de casas 2
    country: "Ranchos",
    address: "Calle San Martín N 3091",
    bedrooms: "1",
    bathrooms: "1",
    surface: "2815.50 m2",
    currency: "",
    price: "",
  },
  {
    id: 6,
    type: "Departamento",
    state: "Venta",
    name: "Departamento Fernando De Arenaza al 100",
    description:
      "EN VENTA: 2 DEPARTAMENTOS EN VENTA EN CHASCOMÚS Departamento en la calle Arenaza: Este departamento cuenta con una amplia cocina comedor equipada con alacena, bajo mesada y termotanque. Además, tiene un dormitorio y un baño completo, ideal para quienes buscan comodidad y funcionalidad en un solo lugar.Monoambiente en la calle Arenaza: Este práctico monoambiente incluye una cocina comedor con mesada, bajo mesada y termotanque, además de un baño completo. Perfecto para quienes necesitan un espacio sencillo y eficiente. Ubicación: Calle Fernando de Arenaza 115, Chascomús.",
    characteristics: {
      Ambientes: "2",
      Dormitorios: "1",
      Baños: "1",
      Cochera: "No",
      Patio: "No",
      Expensas: "Si",
      Ubicación: "Contrafrente",
      Servicios: "Agua corriente, gas natural, electricidad, cloacas",
      "Estado del inmueble": "Muy bueno",
      "Cantidad de plantas": "1",
      "Superficie cubierta": "53 m2",
      "Superficie total": "53 m2",
      "Otros espacios": "Balcón y cocina con desayunador",
    },
    imageCard: apartmentsImages3[0], // Primer imagen de apartamentos
    images: apartmentsImages3, // Todas las imágenes de apartamentos
    country: "Chascomús",
    address: "Calle Fernando De Arenaza al 100",
    bedrooms: "2",
    bathrooms: "1",
    surface: "53 m2",
    currency: "USD",
    price: "55000",
  },
  {
    id: 7,
    type: "Terreno",
    state: "Venta",
    name: "Terreno en calle Cencerro N 320",
    description:
      "EN VENTA: Terreno a metros de la Avenida Juan Manuel de Rosas acceso a Chascomús. Superficie total de 2400 m2. Cuenta con pilar para bajada de luz y perforación, ademas posee cercos lindantes de arboleda con especies como alamos, fresnos, eucalipto y acacio blanco. El cerco de alambrado se encuentra en perfectas condiciones.",
    characteristics: {
      Ambientes: "3",
      Dormitorios: "2",
      Baños: "1",
      Cochera: "No",
      Patio: "Si",
      Ubicación: "Frente",
      Servicios: "Agua corriente, gas natural, electricidad",
      "Estado del inmueble": "Bueno",
      "Cantidad de plantas": "1",
    },
    imageCard: terrainsImages1[0], // Primer imagen de apartamentos
    images: terrainsImages1, // Todas las imágenes de apartamentos
    country: "Chascomús",
    address: "Cencerro N 320",
    surface: "2400 m2",
    currency: "USD",
    price: "65000",
  },
  {
    id: 8,
    type: "Casa",
    state: "Alquiler Temporal",
    name: "Casa en Ruta 20 km 81",
    description:
      "🏠 Casa de alquiler temporal 📍Ruta 20 km 81 Frente al Aero Club Chascomús. Disfruta de una casa totalmente equipada con:\n\n" +
      "🔸 ⁠Televisor con DirecTV\n" +
      "🔸 ⁠Cocina completa con utensilios, microondas, heladera con freezer, pava eléctrica y cocina a gas con horno y 4 hornallas\n" +
      "🔸 ⁠Ventiladores de techo en todos los ambientes y hogar a leña en el living\n" +
      "🔸 ⁠Parrilla, pileta (de temporada), mesa plástica, sillas, sombrilla y garage semi cubierto\n\n" +
      "Incluye sábanas y toallas (no para la pileta).\n\n" +
      "Importante:\n" +
      "🔸 No se permiten visitas, solo personas confirmadas en la reserva\n" +
      "🔸 Se aceptan mascotas con buen comportamiento (informar previamente).",
    characteristics: {
      Frente: "Frente al Aero Club Chascomús",
      "Superficie total": "2400 m2",
    },
    imageCard: housesImages3[0],
    images: housesImages3,
    country: "Chascomús",
    address: "Ruta 20 km 81",
    surface: "2400 m2",
    currency: "",
    price: "",
  },
  {
    id: 9,
    type: "Casa",
    state: "Alquiler Temporal",
    name: "Chacras Laguna Vitel - Club de Campo",
    description:
      "🏠 Alquileres Temporarios en Chacras Laguna Vitel, Club de Campo\n" +
      "📍Ruta 20 km 87,600, Chascomús, Provincia de Buenos Aires\n\n" +
      "🏠 Casas disponibles de 3 y 4 ambientes\n" +
      "🛏️ 2 y 3 dormitorios\n" +
      "🛀 1 y 2 baños\n" +
      "🏊‍♂️ Piscina\n" +
      "🪴🌷☀️ Amplios parques y espacios para disfrutar\n\n" +
      "El barrio posee:\n" +
      "🛒 Proveeduría\n" +
      "🏤 Club House",
    characteristics: {
      Ambientes: "4",
      Dormitorios: "3",
      Baños: "2",
      Piscina: "Sí",
      "Espacios verdes": "Amplios parques",
    },
    imageCard: housesImages4[0],
    images: housesImages4,
    country: "Chascomús",
    address: "Ruta 20 km 87,600",
    currency: "",
    price: "",
  },
  {
    id: 10,
    type: "Duplex",
    state: "Alquiler",
    name: "Impecable Duplex en Alquiler - Solis entre Obligado y Ameghino",
    description: `
  🏠 Impecable Duplex en Alquiler
  🔸 2 habitaciones
  🔸 Cocina comedor
  🔸 Baño
  🔸 Patio
  🔸 Cochera
  🔸 Parrilla
  🔸 Lavadero
  🔸 Súper luminoso
  🔸 Excelente calidad de construcción
  🔸 Impecable estado
  🔸 Todos los servicios
  📍 Solis entre Obligado y Ameghino
  
  📜 IMPORTANTE
  - NO SE ADMITEN MASCOTAS
  - SIN NIÑOS
  - PERSONAS SOLAS O EN PAREJA
  - CONTRATO 24 MESES INDEXA CADA 4 MESES POR IPC
  
  📋 CONDICIONES
  - 1 MES ADELANTADO
  - 1 MES DEPOSITO
  - 1 MES HONORARIOS PROFESIONALES
  - 2 GARANTES CON RECIBO DE SUELDO
  `,
    characteristics: {
      Habitaciones: "2",
      "Cocina comedor": "Sí",
      Baño: "1",
      Patio: "Sí",
      Cochera: "Sí",
      Parrilla: "Sí",
      Lavadero: "Sí",
      Luminosidad: "Súper luminoso",
      "Estado general": "Impecable estado",
      Servicios: "Todos los servicios",
    },
    imageCard: duplexImages1[0],
    images: duplexImages1,
    country: "Chascomús",
    address: "Solis entre Obligado y Ameghino",
    currency: "",
    price: "",
  },
  {
    id: 11,
    type: "Quinta",
    state: "Venta",
    name: "Quinta en Venta - Tornquist N° 963",
    description: `
  📍 Tornquist N° 963
  Sobre 2 lotes
  Superficie aproximada: 1400 m²
  
  La propiedad posee:
  🏠 Cabaña con:
    🛏️ 2 habitaciones
    🛀 Baño
    🍴 Cocina comedor
    🔥 Living con hogar
  
  🏠 Quincho amplio con:
    🔸 Parrilla
    🔸 Baño independiente
  
  🏡 Además:
    🛋️ Amplia terraza y balcón
    🏊‍♂️ Pileta
    🪴 Amplio parque para disfrutar ☀️☀️
  
  🔧 Servicios:
    🔸 Luz 💡
    🔸 Agua 💧
  
  💰 **OPORTUNIDAD**: 35.000 USD
  `,
    characteristics: {
      Superficie: "1400 m² (aproximadamente)",
      Habitaciones: "2",
      Baños: "2 (1 en la cabaña, 1 en el quincho)",
      "Cocina comedor": "Sí",
      Living: "Con hogar",
      Quincho: "Amplio con parrilla y baño independiente",
      Terraza: "Amplia terraza y balcón",
      Pileta: "Sí",
      Parque: "Amplio para disfrutar",
      Servicios: "Luz y Agua",
    },
    imageCard: housesImages5[0], // Reemplazar con una imagen representativa
    images: housesImages5, // Array de imágenes asociadas
    country: "Tornquist",
    address: "Tornquist N° 963",
    currency: "USD",
    price: "35.000",
  },
];
