import React, { useState, useContext } from "react";
import { RiMapPinLine, RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Menu } from "@headlessui/react";
import { HouseContext } from "../../context/HouseContext";

const StateDropdown = () => {
  const { state, setState, states } = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu as="div" className="dropdown relative">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-btn w-full text-left"
      >
        <RiMapPinLine className="dropdown-icon-primary text-primary" />
        <div>
          <div className="text-[15px] font-medium leading-tight">{state}</div>
          <div className="text-[13px]">Selecciona tipo</div>
        </div>
        {isOpen ? (
          <RiArrowUpSLine className="dropdown-icon-secondary text-primary" />
        ) : (
          <RiArrowDownSLine className="dropdown-icon-secondary text-primary" />
        )}
      </Menu.Button>

      <Menu.Items className="dropdown-menu">
        {states.map((state, index) => (
          <Menu.Item
            as="li"
            onClick={() => setState(state)}
            key={index}
            className="cursor-pointer hover:text-primary transition"
          >
            {state}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default StateDropdown;
